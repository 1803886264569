import React from 'react'

import { LinkMock } from '../../components/LinkMock'
import { ListItemHTMLExample } from '../../pages/listat-ja-taulukot/list'
import placeholderImage2 from '../../assets/placeholder-2.jpg'

import ButtonHTMLExample from './ButtonHTMLExample'

const ImageSectionExample = () => (
  <section className="image-section">
    <div
      className="image-section-background"
      style={{ backgroundImage: `url(${placeholderImage2})` }}
    />
    <div className="image-section-container">
      <div className="image-section-content">
        <h2 className="h1">Henkilöasiakas - valitse tilanteesi</h2>
        <ul className="list">
          <ListItemHTMLExample>
            <LinkMock>Olen kiinnostunut työllistämään itseni</LinkMock>
          </ListItemHTMLExample>
          <ListItemHTMLExample>
            <LinkMock>Haluan muutosta työelämääni</LinkMock>
          </ListItemHTMLExample>
          <ListItemHTMLExample>
            <LinkMock>Olen työtön tai jäämässä työttömäksi</LinkMock>
          </ListItemHTMLExample>
        </ul>
        <ButtonHTMLExample
          as={LinkMock}
          color="secondary"
        >
          Kaikki tilanteet
        </ButtonHTMLExample>
      </div>
    </div>
  </section>
)

export { ImageSectionExample }
