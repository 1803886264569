import React, {
  cloneElement,
  ComponentProps,
  ElementType,
  ReactNode
} from 'react'

import { Button } from '@te-digi/styleguide'

type ButtonHTMLExampleProps = {
  as?: ElementType
  children: ReactNode
  className?: string
  color?: ComponentProps<typeof Button>['color']
  disabled?: boolean
  href?: string
  icon?: JSX.Element
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  shape?: 'circle' | 'square'
  onClick?: React.HTMLProps<HTMLButtonElement>['onClick']
  size?: 'xs' | 'sm'
  tabIndex?: number
  type?: string
  variant?: ComponentProps<typeof Button>['variant']
}

const ButtonHTMLExample = ({
  as: Component = 'button',
  children,
  className,
  color = 'primary',
  disabled,
  icon,
  iconLeft,
  iconRight,
  shape,
  size,
  tabIndex,
  type,
  variant,
  ...rest
}: ButtonHTMLExampleProps) => {
  let colorClassName = undefined
  if (color) {
    const btnVariantClassName = variant ? `${variant}-` : ''
    colorClassName = `btn-${btnVariantClassName}${color}`
  }

  return (
    <Component
      className={[
        'btn',
        colorClassName,
        disabled && Component !== 'button' ? 'disabled' : undefined,
        shape ? `btn-${shape}` : undefined,
        size ? `btn-${size}` : undefined,
        className
      ]
        .filter(Boolean)
        .join(' ')}
      disabled={Component === 'button' && disabled}
      tabIndex={tabIndex}
      type={type || (Component === 'button' ? 'button' : undefined)}
      {...rest}
    >
      <span className="btn-content">
        {iconLeft &&
          cloneElement(iconLeft, { className: 'btn-icon-left', size: 'lg' })}
        {icon && cloneElement(icon, { className: 'btn-icon', size: 'lg' })}
        {icon ? (
          <span className="visually-hidden">{children}</span>
        ) : (
          <span className="btn-label">{children}</span>
        )}
        {iconRight &&
          cloneElement(iconRight, { className: 'btn-icon-right', size: 'lg' })}
      </span>
    </Component>
  )
}

export default ButtonHTMLExample
