import React from 'react'

import {
  Link,
  List,
  ListItem,
  Paragraph,
  SpeechBubbleFillIcon
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

export const ListItemHTMLExample = ({
  children
}: {
  children: React.ReactNode
}) => (
  <li className="list-item">
    <IconHTMLExample
      className="list-item-icon"
      name="ChevronRightIcon"
    />
    <span className="list-item-label">{children}</span>
  </li>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="List"
    components={[{ component: List }, { component: ListItem }]}
  >
    <Paragraph>
      Listasta on kolme eri varianttia: väkäset (default), pallurat (unordered)
      ja numerot (ordered). Huomio ei-numeroiduista listoista: mikäli lista on
      linkkilista, käytä oletusarvoa (väkäset). Kun lista on tekstimuotoinen,
      käytä palluroita.
    </Paragraph>
    <Section>
      <Playground>
        <List noMargin>
          <ListItem>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
          </ListItem>
          <ListItem>Sed posuere interdum sem</ListItem>
          <ListItem>
            Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
            sapien
            <List noMargin>
              <ListItem>
                Vivamus placerat lacus vel vehicula scelerisque
              </ListItem>
              <ListItem>Integer ac eros eu tellus dignissim viverra</ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Link as={LinkMock}>Mauris varius diam vitae arcu</Link>
          </ListItem>
          <ListItem>
            Suspendisse <Link as={LinkMock}>facilisis</Link> nulla in lacinia
            laoreet
          </ListItem>
          <ListItem icon={<SpeechBubbleFillIcon color="primary" />}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
          </ListItem>
        </List>
      </Playground>
      <Playground format="html">
        <ul className="list mb-0">
          <ListItemHTMLExample>
            <LinkMock>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien
            </LinkMock>
          </ListItemHTMLExample>
          <ListItemHTMLExample>
            <LinkMock>
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit
            </LinkMock>
          </ListItemHTMLExample>
        </ul>
      </Playground>
    </Section>
    <Section>
      <Playground>
        <>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien.
          </Paragraph>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit
            </ListItem>
            <ListItem>Sed posuere interdum sem</ListItem>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>Mauris varius diam vitae arcu</ListItem>
            </List>
            <ListItem>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien
            </ListItem>
          </List>
        </>
      </Playground>
      <Playground format="html">
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien.
          </p>
          <ul className="mb-0">
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</li>
            <li>Sed posuere interdum sem</li>
            <ul className="mb-0">
              <li>Mauris varius diam vitae arcu</li>
            </ul>
            <li>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien
            </li>
          </ul>
        </>
      </Playground>
    </Section>
    <Section>
      <Playground>
        <>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien.
          </Paragraph>
          <List
            noMargin
            variant="ordered"
          >
            <ListItem>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit
            </ListItem>
            <ListItem>Sed posuere interdum sem</ListItem>
            <ListItem>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien
            </ListItem>
          </List>
        </>
      </Playground>
      <Playground format="html">
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien.
          </p>
          <ol className="mb-0">
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</li>
            <li>Sed posuere interdum sem</li>
            <li>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien
            </li>
          </ol>
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
