import React from 'react'

import homeImageSectionImage from '@te-digi/styleguide/assets/home-image-section.jpg'

import ButtonHTMLExample from './ButtonHTMLExample'

const ImageSectionCenteredExample = () => (
  <section className="image-section image-section-centered">
    <div
      className="image-section-background"
      style={{ backgroundImage: `url(${homeImageSectionImage})` }}
    />
    <div className="image-section-container">
      <div className="image-section-content">
        <h1 className="heading-headline text-center">
          Tervetuloa Työmarkkinatorille
        </h1>
        <p className="lead text-center">
          Työmarkkinatorilta löydät kootusti työelämään liittyvät tietosisällöt
          ja palvelut.
          <br />
          Kirjautumalla sisään saat kaiken irti Työmarkkinatorista jo nyt.
        </p>
        <form
          action="https://kokeile.tyomarkkinatori.fi/Etusivu/Henkiloasiakkaat/Selaa-tyopaikkoja"
          method="get"
        >
          <div className="tmt-home-job-search">
            <input
              aria-label="Etsi työpaikkaa"
              className="form-control tmt-home-job-search-input"
              id="job-search-input"
              name="q"
              placeholder="Etsi työpaikkaa (esim. ammatti, työtehtävät)..."
              type="text"
            />
            <ButtonHTMLExample
              className="tmt-home-job-search-button"
              type="submit"
            >
              Näytä työpaikat
            </ButtonHTMLExample>
          </div>
        </form>
        <p className="h4 text-center text-primary mb-0 mt-lg">
          Työmarkkinatorilla on yhteensä 32 541 työpaikkaa ja 12 456
          työnhakuprofiilia.
        </p>
      </div>
    </div>
  </section>
)

export { ImageSectionCenteredExample }
