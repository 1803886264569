import React from 'react'

import { Select } from '@te-digi/styleguide'

import placeholderImage1 from '../../assets/placeholder-1.jpg'

const ImageSectionReversedExample = () => (
  <section className="image-section image-section-reversed">
    <div
      className="image-section-background"
      style={{ backgroundImage: `url(${placeholderImage1})` }}
    />
    <div className="image-section-container">
      <div className="image-section-content">
        <h1 className="heading-headline">Ammattitieto</h1>
        <p>
          Täältä löydät tietoa ammateista ja aloista, ja voit lukea eri
          ammateissa toimivien ihmisten haastatteluja ja uratarinoita.
          Ammattitieto on tarkoitettu kaikille, jotka haluavat tietoa
          esimerkiksi ammattien ja alojen vertailuun tai ammatinvalinnan ja
          urasuunnittelun tueksi.{' '}
        </p>
        <h3 className="h4 mb-sm">
          Valitse sinua kiinnostava ammatti tai ammattiala:
        </h3>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <Select
                label="Ammatti"
                searchable
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-0">
              <Select
                label="Ammattiala"
                searchable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export { ImageSectionReversedExample }
