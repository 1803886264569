import React from 'react'

import placeholderImage1 from '../../assets/placeholder-1.jpg'

import ButtonHTMLExample from './ButtonHTMLExample'

const ImageSectionButtonsExample = () => (
  <section className="image-section image-section-reversed">
    <div
      className="image-section-background"
      style={{ backgroundImage: `url(${placeholderImage1})` }}
    />
    <div className="image-section-container">
      <div className="image-section-content">
        <h1 className="heading-headline">Henkilöasiakkaat</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis magni
          ab perspiciatis earum delectus numquam voluptate totam veritatis, nam
          nobis.
        </p>
      </div>
      <div className="buttons mb-0">
        <ButtonHTMLExample color="secondary">Luo profiili</ButtonHTMLExample>
        <ButtonHTMLExample color="secondary">
          Selaa työpaikkoja
        </ButtonHTMLExample>
        <ButtonHTMLExample color="secondary">
          Työllistä kotitaloutena
        </ButtonHTMLExample>
        <ButtonHTMLExample color="secondary">Lorem ipsum</ButtonHTMLExample>
      </div>
    </div>
  </section>
)

export { ImageSectionButtonsExample }
