import React from 'react'

import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { StatusMessage } from '../../components/StatusMessage'
import { ImageSectionButtonsExample } from '../../examples/html/ImageSectionButtonsExample'
import { ImageSectionCenteredExample } from '../../examples/html/ImageSectionCenteredExample'
import { ImageSectionExample } from '../../examples/html/ImageSectionExample'
import { ImageSectionReversedExample } from '../../examples/html/ImageSectionReversedExample'

const Page = () => (
  <Content
    heading="ImageSection"
    subHeading="Verkkopalvelu"
    status={[{ type: 'deprecated', version: '16.0.0' }]}
  >
    <Section>
      <StatusMessage
        replacements={['HeroSection']}
        status={{
          type: 'deprecated',
          version: '16.0.0'
        }}
      />
    </Section>
    <Section title="ImageSection">
      <Playground
        format="html"
        example={ImageSectionExample}
        widePreview
      />
      <Playground
        format="html"
        example={ImageSectionReversedExample}
        widePreview
      />
      <Playground
        format="html"
        example={ImageSectionButtonsExample}
        widePreview
      />
      <Playground
        format="html"
        example={ImageSectionCenteredExample}
        widePreview
      />
    </Section>
  </Content>
)

export default Page
